@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";






















































































































































































































.number-control {
  input {
    text-align: center; } }
