@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";














































































































































































.inspector-elem {
  margin-top: m(5);
  background-color: $primary-color;
  .label {
    padding: 0; }
  .headline {
    padding: m(1);
    margin-bottom: m(1);
    color: white();
    background-color: $secondary-color;
    .icon-right {
      @include font('small'); }
    .label {
      @include font('medium'); } }
  .badge {
    display: flex;
    justify-content: space-around;
    margin: 2px m(2);
    padding: m(1) 3px;
    background-color: white();
    .prop {
      display: flex;
      flex-direction: column;
      align-items: center;
      .value {
        @include font('medium');
        color: $primary-color;
        line-height: 1;
        .unit {
          padding-left: 2px;
          @include font('regular', 'small'); } }
      .label {
        padding-top: m(1);
        @include font('tiny');
        text-transform: uppercase;
        line-height: 1; } } }
  .settings {
    display: flex;
    padding: m(1) m(2);
    .icon-gear {
      flex-shrink: 1;
      display: flex;
      align-items: flex-start;
      margin-top: m(1);
      @include font('big');
      color: white(); }
    .wrapper {
      flex-grow: 1;
      padding-left: m(1);
      .badge {
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 1;
        margin: 0;
        padding: m(1) 0 0 0;
        background-color: transparent;
        .prop {
          align-items: flex-start;
          margin-right: m(2);
          .value {
            @include font('default');
            color: white(); }
          .label {
            padding-top: 3px; } } } } }
  .action {
    padding: m(1) m(2) m(2) m(2);
    .button {
      width: 100%; } } }

@include md {
  .inspector-elem {
    .headline {
      .icon-right {
        display: none; } }
    .badge {
      .prop {
        .value {
          @include font('tool');
          line-height: 1; } } }
    .settings {
      .icon-gear {
        display: none; } } } }

