@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";




























.bimobjects-elem {
  display: flex;
  flex-direction: column;
  margin-top: m(1);
  background-color: $primary-color;
  .logo {
    padding: m(2) m(2) m(1) m(2);
    .svg-elem {
      width: 60%; } }
  .copy {
    padding: 0 m(2) m(2) m(2);
    color: white(); }
  .action {
    padding: 0 m(2) m(2) m(2);
    .button {
      width: 100%; } } }

@include sm {
  .bimobjects-elem {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: m(2);
    .logo {
      display: none; }
    .copy {
      padding: m(2); }
    .action {
      padding: m(2);
      .button {
        width: auto; } } } }

@include xs {
  .bimobjects-elem {
    flex-direction: column;
    .copy {
      text-align: center; }
    .action {
      padding-top: 0; } } }
