@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";



























































.label-control {
  display: flex;
  align-items: baseline;
  //margin-bottom: m(1)
  @include font('tool', 'semibold');
  @include ellipsis;
  .required {
    padding-left: 2px;
    color: $warning-color; }
  .unit {
    padding-left: m(1);
    @include font('regular', 'small'); }
  .button-elem {
    margin-left: m(1);
    transform: translateY(1px); } }
