//--------------------------------------------------------------------------
// Import some variables
//--------------------------------------------------------------------------

@import "../../config/style.json";

//--------------------------------------------------------------------------
// Typo
//--------------------------------------------------------------------------

// use font('regular')
$font-family-light:               tktyperegular, Roboto, Calibri, -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;
$font-family-regular:             tktyperegular, Roboto, Calibri, -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;
$font-family-italic:              tktypeitalic, Roboto, Calibri, -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;
$font-family-semibold:            tktypemedium, Roboto, Calibri, -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;
$font-family-bold:                tktypebold, Roboto, Calibri, -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;
$font-family-monospace:           monospace;
$font-family-icons:               tke_icons;

// Base font size per breakpoint, only use in reset
// use font-size-base('xs')
$font-size-base:                  16px, 16px, 16px, 16px;

// Derived font-sizes to work with
// use font('medium') || font-size('medium') || line-height('medium')
$font-tiny:                       0.7rem, 1.4;
$font-small:                      0.9rem, 1.5;
$font-default:                    1rem, 1.625; // p, tke-standard

// tool and medium have same absolute line-height! 1.25 * 1.68 = 1.5 * 1.4
$font-tool:                       1.25rem, 1.68; // special size for this tool
$font-medium:                     1.5rem, 1.4; // h3, blockquote, tke-standard
$font-big:                        2.25rem, 1.35; // h2, tke-standard
$font-bigger:                     2.8rem, 1.3; // h1, special for this tool
$font-jumbo:                      3.7rem, 1.3; //

//--------------------------------------------------------------------------
// Colors
//--------------------------------------------------------------------------

// grey-scale
// use grey([ 1 - 10 ]), where 1 is black, 10 is white and 5 is medium grey
$grey:                            3%, 14%, 21%, 29%, 48%, 71%, 86%, 96%, 98%, 100%;

$orange:                          hsl(30,  88%, 56%); // nicht verwendet
$yellow:                          hsl(49,  73%, 42%); // nicht verwendet
$green:                           rgb(155, 200, 50); // important buttons
$turquoise:                       hsl(171, 100%, 41%); // nicht verwendet
$cyan:                            rgb(0, 160, 240); // tk primary color
$blue:                            rgb(0, 62, 128); // tk hover-color, for important buttons
$purple:                          hsl(294, 27%, 52%); // nicht verwendet
$red:                             hsl(348, 100%, 43%); // nicht verwendet

//--------------------------------------------------------------------------
// Element and Text colors
//--------------------------------------------------------------------------

$primary-color:                   $cyan;
$secondary-color:                 $blue;
$warning-color:                   $red; // nicht verwendet
$success-color:                   $green;

$text-color:                      rgb(75, 85, 100);
$subtext-color:                   grey(5); // nicht verwendet
$headline-color:                  black();
$link-color:                      $cyan;
$quote-color:                     grey(5); // nicht verwendet

$link-active-color:               $cyan;
$body-color:                      grey(8);
$content-color:                   white();
$border-color:                    rgb(217, 222, 232);

$opacity-soft:                    .75;
$opacity-softer:                  .65;

//--------------------------------------------------------------------------
// Responsive
//--------------------------------------------------------------------------

// Values given are MAX-width of the device:
//
// 1441 - *  lg / widescreen
// 1440 - 835 is md / desktop (and tablet landscape mode)
// 834 - 481 is sm / tablet (portrait mode and mobile landscape mode)
// 480 - 0 is xs / mobile (for portrait mode only)
//
// https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
//
// Breakpoints are set to rounded value in maximum, because maximum widths
// of pictures etc. can be computed better.

//--------------------------------------------------------------------------
// Dimensions, padding, margin
//--------------------------------------------------------------------------

// number of columns
$col-count:                       12;

// content-width + 2 x content-padding = width of content block
// use content-width('xs')
$content-width:                   1344px, 1128px, 928px, 594px;

// left and right space between body and content
// use content-padding('xs')
$content-padding:                 36px, 36px, 25px, 18px;

// space between columns, dividable by 2
// use col-gap('xs')
$col-gap:                         14px, 14px, 12px, 10px;

// horizontal and vertical distance between text-blocks
// padding for textblocks with border or background
// use function: padding: m(3)
$measure:                         .5rem;

//--------------------------------------------------------------------------
// Borders, Radius
//--------------------------------------------------------------------------

$radius-small:                    3px;
$radius:                          5px;
$radius-large:                    8px;
$radius-rounded:                  290486px;

//--------------------------------------------------------------------------
// Buttons
//--------------------------------------------------------------------------

// only used for icons
$button-height:                   2.4em; // em, not rem, because different sizes

//--------------------------------------------------------------------------
// Form
//--------------------------------------------------------------------------

$form-font-size:                 nth($font-medium, 1);
$form-line-height:               1.4;
$form-textarea-height:           8rem;
$form-input-indent:              .6rem;
$form-vertical-padding:          9px; // px!
$form-color:                     $primary-color; // grey(2)
$form-background-color:          white();
$form-disabled-color:            grey(6);
$form-disabled-background-color: grey(8);
$form-error-color:               $warning-color;
$form-placeholder-color:         grey(6);
$form-border-color:              grey(6);
$form-border-radius:             0;
$form-control-color:             $primary-color;

//--------------------------------------------------------------------------
// Form
//--------------------------------------------------------------------------

$z-index-base:                   100;
$z-index-tooltip:                200;
$z-index-slider:                 700;
$z-index-overlay:                800;
$z-index-modal:                  900;
