@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";
























































































































































































































































































































.configurator-page {
  padding-top: m(2);
  .step {
    .headline {
      margin-bottom: m(4);
      .col {
        .headline-step {
          @include font('tool'); }
        h2 {
          @include font('regular');
          line-height: 1;
          .prefix {
            padding-right: m(2);
            @include font('bold'); } } } }
    .subhead {
      margin-bottom: m(1);
      h2 {
        span {
          margin-left: m(1);
          @include font('regular', 'tool'); } } }
    .control-row {} }
  .actions {
    margin-top: m(3);
    padding-top: m(4);
    border-top: 2px solid grey(6);
    .col {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .progress {
      text-align: center;
      #progress-circle {
        width: 30%; } }
    .button {
      width: 100%; } }
  .result {
    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &.drawing {
        .button {
          padding-top: m(3);
          padding-bottom: m(3); } } }
    .button {
      width: 100%; } } }

@include md {
  .configurator-page {
    .actions {
      margin-top: m(1); } } }

@include xs {
  .configurator-page {
    .result {
      .col {
        &.prev {
          order: 2; }
        &.drawing {
          order: 1; }
        &.startagain {
          order: 3; } } } } }
