@charset "utf-8";

@import "../../../styles/lib/mixins.sass";
@import "../../../styles/lib/variables.sass";









































































































.configurator-step-5 {
  .headline-step {
    opacity: 0; }
  .sketch {
    padding: m(2) m(3) m(3) m(3);
    background-color: white(); }
  .sideview {}
  .transportation {}
  .frontview {}
  .energyefficiency {
    margin-bottom: m(2);
    .ee-classes {
      display: flex;
      justify-content: space-between;
      margin-top: m(1);
      .ee-class {
        padding: m(1);
        width: 11%;
        @include font('tool');
        line-height: 1.4;
        text-align: center;
        color: $primary-color;
        border: 1px solid $primary-color;
        &.is-active {
          color: white();
          background-color: $primary-color; } } } } }
