@charset "utf-8";

@import "../../../styles/lib/mixins.sass";
@import "../../../styles/lib/variables.sass";





















































































































































.configurator-step-2 {
  .floors-count-control {
    margin-bottom: 0; }
  .pit-control {
    @include font('default');
    align-items: center; } }
