//--------------------------------------------------------------------------
// Typo
//--------------------------------------------------------------------------

@mixin font($var1: '', $var2: '') {
  @if $var1 == 'light' or $var2 == 'light' {
    font-family: $font-family-light; }
  @else if $var1 == 'regular' or $var2 == 'regular' {
    font-family: $font-family-regular; }
  @else if $var1 == 'italic' or $var2 == 'italic' {
    font-family: $font-family-italic; }
  @else if $var1 == 'semibold' or $var2 == 'semibold' {
    font-family: $font-family-semibold; }
  @else if $var1 == 'bold' or $var2 == 'bold' {
    font-family: $font-family-bold; }

  @if $var1 == 'tiny' or $var2 == 'tiny' {
    font-size: font-size('tiny');
    line-height: line-height('tiny'); }
  @else if $var1 == 'small' or $var2 == 'small' {
    font-size: font-size('small');
    line-height: line-height('small'); }
  @else if $var1 == 'default' or $var2 == 'default' {
    font-size: font-size('default');
    line-height: line-height('default'); }
  @else if $var1 == 'tool' or $var2 == 'tool' {
    font-size: font-size('tool');
    line-height: line-height('tool'); }
  @else if $var1 == 'medium' or $var2 == 'medium' {
    font-size: font-size('medium');
    line-height: line-height('medium'); }
  @else if $var1 == 'big' or $var2 == 'big' {
    font-size: font-size('big');
    line-height: line-height('big'); }
  @else if $var1 == 'bigger' or $var2 == 'bigger' {
    font-size: font-size('bigger');
    line-height: line-height('bigger'); }
  @else if $var1 == 'jumbo' or $var2 == 'jumbo' {
    font-size: font-size('jumbo');
    line-height: line-height('jumbo'); } }

@function font-size-base ($breakpoint: 'lg') {
  $key: breakpoint($breakpoint);
  @return nth($font-size-base, $key); }

@function font-size($size: 'default') {
  @if $size == 'tiny' {
    @return nth($font-tiny, 1); }
  @else if $size == 'small' {
    @return nth($font-small, 1); }
  @else if $size == 'default' {
    @return nth($font-default, 1); }
  @else if $size == 'tool' {
    @return nth($font-tool, 1); }
  @else if $size == 'medium' {
    @return nth($font-medium, 1); }
  @else if $size == 'big' {
    @return nth($font-big, 1); }
  @else if $size == 'bigger' {
    @return nth($font-bigger, 1); }
  @else if $size == 'jumbo' {
    @return nth($font-jumbo, 1); } }

@function line-height($size: 'default') {
  @if $size == 'tiny' {
    @return nth($font-tiny, 2); }
  @else if $size == 'small' {
    @return nth($font-small, 2); }
  @else if $size == 'default' {
    @return nth($font-default, 2); }
  @else if $size == 'tool' {
    @return nth($font-tool, 2); }
  @else if $size == 'medium' {
    @return nth($font-medium, 2); }
  @else if $size == 'big' {
    @return nth($font-big, 2); }
  @else if $size == 'bigger' {
    @return nth($font-bigger, 2); }
  @else if $size == 'jumbo' {
    @return nth($font-jumbo, 2); } }

//--------------------------------------------------------------------------
// Colors
//--------------------------------------------------------------------------

@function grey($key, $opacity: 1) {
  $color: nth($grey, $key);
  @return hsla(0, 0%, $color, $opacity); }

@function white($opacity: 1) {
  @return grey(10, $opacity); }

@function black($opacity: 1) {
  @return grey(1, $opacity); }

//--------------------------------------------------------------------------
// Icons
//--------------------------------------------------------------------------

@mixin icon($name: null, $position: 'before') {
  @if $position == 'after' {
    &:after {
      font-family: $font-family-icons !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      color: inherit;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      @if $name {
        content: map-get($icons, $name); } } }
  @else {
    &:before {
      font-family: $font-family-icons !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      color: inherit;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      @if $name {
        content: map-get($icons, $name); } } } }


//--------------------------------------------------------------------------
// Measure and distance helper
//--------------------------------------------------------------------------

@function content-width ($breakpoint: 'lg') {
  $key: breakpoint($breakpoint);
  @return nth($content-width, $key) + (2 * nth($content-padding, $key)); }

@function content-padding ($breakpoint: 'lg') {
  $key: breakpoint($breakpoint);
  @return nth($content-padding, $key); }

@function col-gap ($breakpoint: 'lg') {
  $key: breakpoint($breakpoint);
  @return nth($col-gap, $key); }

@function m ($num: 1) {
  @return $measure * $num; }

@function breakpoint($name: 'lg') {
  @if $name == 'md' {
    @return 2; }
  @else if $name == 'sm' {
    @return 3; }
  @else if $name == 'xs' {
    @return 4; }
  @else {
    @return 1; } }

//--------------------------------------------------------------------------
// Helper
//--------------------------------------------------------------------------

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

//--------------------------------------------------------------------------
// Elements
//--------------------------------------------------------------------------

@mixin loader {
  animation: spinAround 500ms infinite linear;
  border: 2px solid grey(7);
  border-radius: $radius-rounded;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em; }

@mixin placeholder {
  $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input';
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content; } } }

//--------------------------------------------------------------------------
// Responsive
//--------------------------------------------------------------------------

@mixin from($width) {
  @media screen and (min-width: $width + 1px) {
    @content; } }

@mixin until($width) {
  @media screen and (max-width: $width) {
    @content; } }

@mixin lg {
  $max: map-get($breakpoints, 'lg');
  @media screen and (max-width: #{$max}px) {
    @content; } }

@mixin lg-only {
  $min: map-get($breakpoints, 'md') + 1;
  $max: map-get($breakpoints, 'lg');
  @media screen and (min-width: #{$min}px) and (max-width: #{$max}px) {
    @content; } }

@mixin md {
  $max: map-get($breakpoints, 'md');
  @media screen and (max-width: #{$max}px), print {
    @content; } }

@mixin md-only {
  $min: map-get($breakpoints, 'sm') + 1;
  $max: map-get($breakpoints, 'md');
  @media screen and (min-width: #{$min}px) and (max-width: #{$max}px) {
    @content; } }

@mixin sm {
  $max: map-get($breakpoints, 'sm');
  @media screen and (max-width: #{$max}px) {
    @content; } }

@mixin sm-only {
  $min: map-get($breakpoints, 'xs') + 1;
  $max: map-get($breakpoints, 'sm');
  @media screen and (min-width: #{$min}px) and (max-width: #{$max}px) {
    @content; } }

@mixin xs {
  $max: map-get($breakpoints, 'xs');
  @media screen and (max-width: #{$max}px) {
    @content; } }

@mixin xs-only {
  $max: map-get($breakpoints, 'xs');
  @media screen and (max-width: #{$max}px) {
    @content; } }

//--------------------------------------------------------------------------
// Shadows
//--------------------------------------------------------------------------

@mixin soft-shadow {
  box-shadow: 0 2px 10px rgba(0,0,0,.2); }

//--------------------------------------------------------------------------
// Buttons
//--------------------------------------------------------------------------

@mixin button-color($text, $background, $background-hover: '') {
  color: $text;
  background-color: $background;
  border-color: $background;
  &:not(.is-disabled):hover {
    @if $background-hover != '' {
      background-color: $background-hover;
      border-color: $background-hover; }
    @else {
      background-color: darken($background, 20%);
      border-color: darken($background, 20%); } }
  &.is-outline,
  &.is-icon {
    color: $background;
    background-color: transparent;
    &:not(.is-disabled):hover {
      background-color: transparent;
      @if $background-hover != '' {
        color: $background-hover;
        border-color: $background-hover; }
      @else {
        color: darken($background, 20%);
        border-color: darken($background, 20%); } } }
  &.is-icon {
    border-color: transparent;
    &:not(.is-disabled):hover {
      border-color: transparent; } }
  &.is-disabled {
    opacity: .4;
    cursor: default; } }

@mixin button-color-white() {
  color: grey(5);
  background-color: white();
  border-color: white();
  &:not(.is-disabled):hover {
    background-color: grey(7);
    border-color: grey(7); }
  &.is-outline,
  &.is-icon {
    color: white();
    background-color: transparent;
    border-color: white();
    &:not(.is-disabled):hover {
      color: grey(7);
      background-color: transparent;
      border-color: grey(7); } }
  &.is-icon {
    border-color: transparent;
    &:not(.is-disabled):hover {
      border-color: transparent; } }
  &.is-disabled {
    opacity: .25;
    cursor: default; } }

@mixin button-size($font-size) {
  @if $font-size == 'default' {
    padding: .75em 1.5em; }
  @if $font-size == 'tool' {
    padding: .2em 1.5em; }
  @else {
    padding: .4em 1.5em; }
  @include font($font-size, 'semibold');
  &:before {
    font-size: font-size($font-size); }
  &.is-narrow {
    padding: 0 .2em; }
  &.is-full-width {
    width: 100%;
    max-width: 100%;
    justify-content: center;
    @include ellipsis; }
  &.is-icon {
    padding: 0;
    height: $button-height;
    width: $button-height;
    justify-content: center;
    align-items: center;
    &:before {
      padding: 0; }
    &.is-narrow {
      height: auto;
      width: auto; } }
  &.icon-only { // auto-set by button-elem
    &:before {
      padding: 0; } } }
